<template>
  <div class="create-property">
    <PropertyAppbar />
    <div class="ma-4">
      <PropertyForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Property
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import PropertyAppbar from '@/views/Home/Property/components/PropertyAppbar'
import PropertyForm from '@/views/Home/Property/components/PropertyForm'

export default {
  components: {
    PropertyAppbar,
    PropertyForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'property/clearPropertyDetails',
    }),
  },
}
</script>
<style lang="scss">
.create-property {
  //
}
</style>
